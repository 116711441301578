
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ReportRepository from '@/repositories/ReportRepository';
import ReportTypesRepository from '@/repositories/ReportTypesRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { ReportData } from '@/interfaces/components/Statistics/CMSReport';
import { ReportType } from '@/interfaces/components/Statistics/ReportType';
import CMSUser from '@/models/CMSUser';
import UserGroup from '@/models/UserGroup';
import Report from '@/models/Report';
import ReportTypes from '@/models/ReportTypes';

@Component({
    name: 'StatisticsModuleCms',
    components: {},
})
export default class StatisticsAddNew extends Vue {
    @Prop({ required: false }) private reportId!: string;
    @Prop({ required: false, default: false }) private isAdmin!: boolean;

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedRowKeys: string[] = [];
    private selectedUsers: any[] = [{ id: '1' }, { id: '2' }, { id: '3' }].map((userGroup) => {
        return { ugid: userGroup.id, users: [] };
    });
    // Main object for creating reports
    private formData: ReportData = {
        type: '',
        name: '',
        isDefault: false,
        usersData: [],
    };

    private testUsers = ['1', '2', '3'];

    private get columns(): any[] {
        return [
            {
                title: this.$t('UG ID').toString(),
                key: 'ugid',
                dataIndex: 'ugid',
            },
            {
                title: this.$t('UG Name').toString(),
                key: 'ugname',
                dataIndex: 'ugname',
            },
            {
                title: this.$t('Users').toString(),
                key: 'users',
                dataIndex: 'users',
                scopedSlots: { customRender: 'users' },
            },
        ];
    }

    private get allUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get reportTypes(): ReportType[] {
        return ReportTypesRepository.getAll();
    }

    private get reportById(): any {
        return ReportRepository.getById(this.reportId);
    }

    // Initial data for table *UserGroup* *Users*
    // BE needs to enable fetching all users for specific user group
    private get tableData() {
        const data = [];

        data.push({
            key: this.currentUserGroup?.id,
            ugid: this.currentUserGroup?.id,
            ugname: this.currentUserGroup?.name,
            users: this.allUsers,
        });

        return data;
    }

    private selectUsersForTable(ugid: string) {
        const selectedUsers = this.selectedUsers.find((userGroup) => {
            return userGroup.ugid === ugid;
        });
        return selectedUsers ? selectedUsers.users : [];
    }

    private updateLastSelectedRow(userGroup: any, isSelected: boolean) {
        // // Select all users from selected user group and add/remove all users for that group
        const selectedUserGroup = this.selectedUsers.find((ug) => {
            return ug.ugid === userGroup.ugid;
        });

        switch (isSelected) {
            case true:
                selectedUserGroup.users = userGroup.users.map((user: any) => {
                    return user.id;
                });
                break;
            case false:
                selectedUserGroup.users = [];
                break;
        }
    }

    // Update selected rows
    private onSelectChange(selectedRowKeys: string[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    // Update selected users based on selected user groups/rows
    private onUsersChange(id: string, ugid: string, action: string) {
        const selectedUserGroup = this.selectedUsers.find((ug) => {
            return ug.ugid === ugid;
        });

        switch (action) {
            case 'select':
                selectedUserGroup.users.push(id);
                break;
            case 'deselect':
                selectedUserGroup.users.splice(selectedUserGroup.users.indexOf(id), 1);
                break;
        }
    }

    // Select/deselect all users from selected user group
    private changeAllRows(selected: boolean) {
        switch (selected) {
            case true:
                this.selectedUsers.forEach((userGroup: any) => {
                    if (userGroup.ugid === '2') {
                        userGroup.users = this.testUsers;
                    } else {
                        userGroup.users = this.allUsers.map((user: CMSUser) => user.id);
                    }
                });
                break;
            case false:
                this.selectedUsers.forEach((userGroup: any) => (userGroup.users = []));
                break;
        }
        this.selectedUsers;
    }

    private addUserIdsToForm() {
        this.formData.usersData = [...this.selectedUsers];
    }

    // Create new report and redirect to reports list
    private async createReport() {
        if (!this.isAdmin && !!this.reportId) {
            this.$notification.error({
                message: this.$t('You are not allowed to edit this report!'),
                description: '',
            });
            return;
        }

        this.loadingOverlay.start();

        // Add users to formData
        const data = this.addUserIdsToForm();

        if (!this.checkAreFieldsValid()) {
            this.loadingOverlay.stop();
            return;
        }

        try {
            if (this.reportId) {
                await Report.updateExisting(this.reportId, this.formData);
            } else {
                // API call to the backend to create a new report with the data from the form
                await Report.createNew(this.formData);
            }
        } catch (err) {
            this.$notification.error({
                message: this.$t('Error while creating report!'),
                description: '',
            });
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Report successfully created!'),
            description: '',
        });

        // Proceed to the reports list
        this.$emit('closePopup');
    }

    private checkAreFieldsValid() {
        if (!this.formData.type) {
            this.$notification.error({
                message: this.$t('Please select a type!'),
                description: '',
            });
            return false;
        }

        if (!this.formData.name) {
            this.$notification.error({
                message: this.$t('Please enter a name!'),
                description: '',
            });
            return false;
        }

        if (!this.formData.usersData.length) {
            this.$notification.error({
                message: this.$t('Please select at least one user!'),
                description: '',
            });
            return false;
        }

        return true;
    }

    private async fetchInitialRoltekData() {
        try {
            Promise.allSettled([CMSUser.getAll(), UserGroup.getAll()]);
        } catch (e) {
            Promise.reject(e);
        }

        Promise.resolve();
    }

    private async fetchDefaultReportsData() {
        try {
            Promise.allSettled([await this.fetchInitialRoltekData(), await ReportTypes.getAll()]);
        } catch (e) {
            Promise.reject(e);
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    // Update users from selected report
    private mapUsersFromCurrentlyFetchedReport(users: any) {
        return users
            .filter((user: any) => !user.isArchived)
            .map((user: any) => {
                return user.id;
            });
    }

    private async formatSelectedReportData() {
        if (this.reportById) {
            this.formData = {
                type: this.reportById.type,
                name: this.reportById.name,
                isDefault: this.reportById.isDefault,
                usersData: [],
            };
            // Get ids from fetched users
            this.selectedUsers[0].users = this.mapUsersFromCurrentlyFetchedReport(this.reportById.users);
        }

        return Promise.resolve();
    }

    private setCorrectFiltering(searchQuery: string, instance: any) {
        if (Array.isArray(instance.componentOptions)) {
            return false;
        }

        return instance.componentOptions.children[0].text.toLowerCase().includes(searchQuery.toLowerCase());
    }

    private async mounted() {
        this.loadingOverlay.start();

        // Fetch data for creating reports and for editing reports if in edit mode
        await this.fetchDefaultReportsData();
        if (this.reportId) {
            await Report.getById(this.reportId);
            await this.formatSelectedReportData();
        }
        this.loadingOverlay.stop();
    }

    private created() {
        EventBus.$on(EventBusEvents.createStatisticsReport, this.createReport);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.createStatisticsReport, this.createReport);
    }
}
