import Report from '@/models/Report';

export default class ReportRepository {
    public static getAll() {
        return Report.all();
    }

    public static getById(id: string) {
        return Report.query()
            .whereId(id)
            .withAll()
            .first();
    }

    public static delete(id: string) {
        Report.delete(id);
    }
}
